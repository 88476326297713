import { Location } from "@reach/router";

import { Footer } from "../components/footer";
import { Navbar } from "../components/navbar";

import styles from "./layout.module.scss";
import "./layout.scss";

export function Layout({ children }: { children: React.ReactNode }) {
  return (
    <div className={styles.layout}>
      <Navbar />
      {children}
      <Location>
        {({ location }) => (location.pathname === "/" ? null : <Footer />)}
      </Location>
    </div>
  );
}

export default Layout;
