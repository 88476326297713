exports.components = {
  "component---src-pages-data-views-data-view-id-summary-tsx": () => import("./../../../src/pages/data_views/{DataView._id}/summary.tsx" /* webpackChunkName: "component---src-pages-data-views-data-view-id-summary-tsx" */),
  "component---src-pages-data-views-index-tsx": () => import("./../../../src/pages/data_views/index.tsx" /* webpackChunkName: "component---src-pages-data-views-index-tsx" */),
  "component---src-pages-datasets-dataset-id-show-files-tsx": () => import("./../../../src/pages/datasets/{Dataset._id}/show_files.tsx" /* webpackChunkName: "component---src-pages-datasets-dataset-id-show-files-tsx" */),
  "component---src-pages-datasets-index-tsx": () => import("./../../../src/pages/datasets/index.tsx" /* webpackChunkName: "component---src-pages-datasets-index-tsx" */),
  "component---src-pages-dmca-index-tsx": () => import("./../../../src/pages/dmca/index.tsx" /* webpackChunkName: "component---src-pages-dmca-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-index-tsx": () => import("./../../../src/pages/privacy/index.tsx" /* webpackChunkName: "component---src-pages-privacy-index-tsx" */),
  "component---src-pages-tos-index-tsx": () => import("./../../../src/pages/tos/index.tsx" /* webpackChunkName: "component---src-pages-tos-index-tsx" */)
}

