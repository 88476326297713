import { LinkGetProps, Location } from "@reach/router";
import clsx from "clsx";
import { Link } from "gatsby";

import logo from "../../images/logo.svg";

import styles from "./navbar.module.scss";

const getActiveClass = ({ isPartiallyCurrent }: LinkGetProps) =>
  isPartiallyCurrent ? { className: "active" } : {};

export function Navbar() {
  return (
    <div className={styles.navbar}>
      <Location>
        {({ location }) => {
          const isHomepage = location.pathname === "/";
          return (
            <div
              className={clsx(styles.content, {
                [styles.fullWidth]: isHomepage,
              })}
            >
              <Link to="/">
                <img src={logo} className={styles.logo} />
              </Link>
              {isHomepage ? null : (
                <>
                  <Link getProps={getActiveClass} to="/data_views">
                    Data Views
                  </Link>
                  <Link getProps={getActiveClass} to="/datasets">
                    Datasets
                  </Link>
                </>
              )}
            </div>
          );
        }}
      </Location>
    </div>
  );
}
