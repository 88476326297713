import styles from "./footer.module.scss";

export function Footer() {
  return (
    <div className={styles.footer}>
      <div className={styles.content}>
        <div className={styles.column}>
          <div>&copy; 2023 Citrine Informatics</div>
          <div>2629 Broadway, Redwood City, CA 94063</div>
        </div>
        <div className={styles.column}>
          <a href="/privacy">Privacy Policy</a>
          <a href="/tos">Terms of Service</a>
          <a href="/dmca">Copyright Notice</a>
        </div>
      </div>
    </div>
  );
}
